@import "node_modules/bootstrap/scss/bootstrap";
@import 'colors';
@import 'fonts';
@import 'global';
@import 'header';
@import 'tables';
@import 'section-one';
@import 'section-two';
@import 'section-syringe';
@import 'section-three';
@import 'section-four';
@import 'safety-section';
@import 'isi';
@import 'footer';
